<template>
  <section>
    <div class="content-header">
      <h2>Black identity</h2>
    </div>
    <div class="content-wrapper">
      <p>There is a myth about anti-Black racism in Canada, that it “doesn’t exist” and that the “real” anti-Black racism exists in the United States. But this is untrue and unhelpful in battling anti-Black racism in Canada. Anti-Black racism exists in Canada at a cultural, interpersonal, systemic and institutional level. </p>
      <p>According to the 2016 Census, Black Canadians comprised 3.5% of Canada’s total population and 15.6% of visible minorities in Canada (Government of Canada, 2019). Despite this, in 2019, hate crimes targeting Black people accounted for 18% of all hate crimes, and 38% of all racially motivated hate crimes, making these atrocities the most commonly reported hate crimes (Government of Canada, 2021). </p>
      <p>As one Ivey Honours Business Administration (HBA) student describes: </p>
      <div class="panel">
        <p><em>“I am proudly Black. I view my race as a key part of my identity and embrace it. I reject that anti-Black racism is a reflection of Black people or that being a target of any system of oppression ever indicates that a marginalized group is inferior. Because of the systematic barriers in our society, I am often one of few Black people in the room. Sometimes, especially when I am in a new environment, this causes me to feel isolated or play the role of a casual observer. Being aware of the widely held stereotypes held against Black people, I am conscious of my choices including the way I dress, speak and act in less diverse settings. Knowing that so many Black people have been denied opportunities, assaulted, murdered... for no other reason than being Black is a constant weight I carry. </em></p>
        <p><em>I have also developed an eagerness and determination to prove that these negative stereotypes are not true but recognize that this is no easy task. Because I know what it feels like to be stared at and judged for no other reason but my race, I always try to treat everyone fairly and not succumb to unconscious bias. I have not lost all hope though, and I do believe that we can move towards a more equitable society. As sad as it is to say, the struggle has become so evident that it’s almost impossible to ignore. People are more willing to listen, to learn and to stand up as an ally. When we unite, anything is possible.” </em></p>
      </div>
      <p class="pt-3">Racism against Black people is deeply entrenched in our society within a variety of institutions as well—from the healthcare system to the justice system. Let’s examine our education system in particular. From youth, Black students are disempowered to pursue university education. Black students have historically been streamed into general and basic level applied programs. This reduces their changes of pursuing higher education and effectively limiting them from certain career paths. This is indicative of the low expectations that schools and teachers have for these students (Policy Paper - African Canadian Legal Clinic, n.d.). Additionally, in a survey conducted in 2015, 94% of Black youth aged 15-25 indicated they would like to complete a university degree, yet only 60% thought it was possible. Non-Black people surveyed reflected percentages of 82% and 79% for both questions respectively (Five Charts That Show What Systemic Racism Looks like in Canada, 2020). </p>
      <div class="accordion">
        <accordion
          headerText="Black in business "
          accordionID="collapse01"
        >
          <p>In the corporate world, Black people also face adversity. Anti-Black discrimination in the workplace ranges from microaggressions to direct institutionalized policies (Diverse Representation in Leadership: A Review of Eight Canadian Cities, 2020, p. 35). Microaggressions may include receiving questions and doubts about their competency, facing stereotypes and racist “jokes”, or having qualifications overlooked/not recognized (The Black Experience Project, 2017, p.{{'\xa0'}}41). </p>
          <p>At the 2021 Ivey Leader Character and Candour Conference, Wes Hall delivered a powerful speech. He captured the adversity that he has faced in the workplace as a Black man. Insights from his talk can be read <a
              href="https://www.ivey.uwo.ca/news/news-ivey/2021/february/use-leadership-to-address-systemic-racism-character-and-candour-conference/"
              target="_blank"
            >here</a>. </p>
          <p>In London, Black people comprise 1.7% of all board positions while comprising 2.5% of the population (Diverse Representation in Leadership: A Review of Eight Canadian Cities, 2020, p. 49). In Toronto, Black people are underrepresented on boards of directors in the corporate sector where they comprise 0.3% of board members, despite representing 7.5% of the population in the GTA (37). The underrepresentation of Black people in the corporate world is a culmination of systemic and cultural racism that prevents Black people from engaging with certain institutions—professional and academic. </p>
        </accordion>
        <accordion
          headerText="Intersecting Black identities "
          accordionID="collapse02"
        >
          <p>To truly understand the Black experience, it’s important to first understand the concept of intersectionality, coined by Dr. Kimberlé Crenshaw in 1989.</p>
          <div class="panel">
            <p><em>“I argue that Black women are sometimes excluded from feminist theory and antiracist policy discourse because both are predicated on a discrete set of experiences that often does not accurately reflect the interaction of race and gender. These problems of exclusion cannot be solved simply by including Black women within an already established analytical structure. Because the intersectional experience is greater than the sum of racism and sexism, any analysis that does not take intersectionality into account cannot sufficiently address the particular manner in which Black women or subordinated.”(Crenshaw, 1989, p. 140) </em></p>
          </div>
          <p class="pt-2">Since Dr. Crenshaw coined the phrase, intersectionality has been extended to identities beyond race and gender. </p>
        </accordion>
        <accordion
          headerText="Other intersections "
          accordionID="collapse03"
        >
          <p>Black 2SLGBTQQIA+ people are often excluded in celebrations of both Black people’s and celebrations of 2SLGBTQQIA+ people’s accomplishments. This results in a lack of health supports and community events for Black 2SLGBTQQIA+ people (Neustaeter, 2021). </p>
          <p>Black Canadians are also twice as likely to be considered low-income than white Canadians and 4% more likely than non-Black racialized Canadians. Additionally, first generation Black Canadians make $13,000 less than first generation white Canadians (Five Charts That Show What Systemic Racism Looks like in Canada, 2020). These disparities are resultant of a history of anti-Black racism in Canadian society and institutions. </p>
          <p>Within the identities discussed there are even more intersectional experiences. Black men with low socioeconomic status (SES) have different experiences with oppression than Black men with high SES or non-Black men with low SES. The same is true for Black queer women versus non-Black queer persons or Black straight women. This leads to a broader discussion on the general experience of Black people in Canada. </p>
          <p>In order to further equity for Black students at your institution, it’s important to continue conversations about anti-Black racism and engage in meaningful demonstrations of anti-racist practices. Black students deserve to feel accepted and supported by their community, and that involves active listening and learning about their experiences. </p>
        </accordion>
      </div>

    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
